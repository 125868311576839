import React from 'react'
import './NewYearLocationSection.css'
import { new_year_location_icon, question_icon } from '../../../iconsFolder/icons'
import { changeNewYearSection_3_addres, changeNewYearSection_3_addresLink, changeNewYearSection_3_text, newYearAllInfo, newYearIsChange } from '../../../store/slices/NewYearSlice/NewYearSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectData } from '../../../store/slices/GetProjectSlice/GetProjectSlice';
import { useTranslation } from 'react-i18next';
import TutorialVideo from '../../TutorialVideo/TutorialVideo';

function NewYearLocationSection({index, section}) {
  const isChange = useSelector(newYearIsChange);
  const newYearInfo = useSelector(newYearAllInfo);
  const respProject = useSelector(selectProjectData);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  return (
    <div className='new_year_location_section'>
        <div className="container">
            <h2 className='new_year_location_section_title'>{t('new_year_location_section_title')}</h2>
            {isChange ==='view' ? (<div className='new_year_location_section_content'>
                <h3>{respProject?.data?.date || newYearInfo?.date || '22.12.24'}</h3>
                <p>{section?.text || newYearInfo?.section_3_text || t('new_year_location_section_text')}</p>
                <div className='new_year_location_section_content_addres'>
                    <span>{new_year_location_icon}</span>
                    <span>{section?.address || newYearInfo?.section_3_addres || t('new_year_location_section_addres')}</span>
                </div>

                <a target='_blank' href={ section?.address_link || newYearInfo?.section_3_addresLink || 'https://yandex.by/maps/10262/yerevan/?ll=44.592467%2C40.252896&mode=routes&rtext=40.157360%2C44.510709~40.253241%2C44.591325&rtt=auto&ruri=~ymapsbm1%3A%2F%2Forg%3Foid%3D40482897162&z=18'} className='new_year_location_section_content_button'>{t('new_year_location_btn')}</a> 
            </div>)
             : (
              <div className='new_year_location_section_edit_content'>
                  <div className='new_year_location_section_edit_content_input_div'>
                      <span>{t('new_year_placeholders.10')}</span>
                      <input type="text" value={newYearInfo?.section_3_text}  onChange={(e) => dispatch(changeNewYearSection_3_text(e.target.value))}/>
                  </div>

                  <div className='new_year_location_section_edit_content_input_div'>
                      <span>{t('new_year_placeholders.11')}</span>
                      <input type="text" value={newYearInfo?.section_3_addres}  onChange={(e) => dispatch(changeNewYearSection_3_addres(e.target.value))} />
                  </div>

                  <div className='new_year_location_section_edit_content_input_div'>
                      <span>{t('new_year_placeholders.12')}</span>
                      <input type="text" value={newYearInfo?.section_3_addresLink} onChange={(e) => dispatch(changeNewYearSection_3_addresLink(e.target.value))} />
                      <TutorialVideo/>
                  </div>
              </div>
             )
          }
        </div>
    </div>
  )
}

export default NewYearLocationSection