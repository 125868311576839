import React, { useState, useRef, useEffect } from 'react';
import './NewYearHeader.css';
import { acbaBancLogo, bottom_animation_img, webexLogo } from '../../../images/images';
import { changeNewYearDate, changeNewYearInvitationName, changeNewYearSection1Images, changeNewYearSection_1_text, newYearAllInfo, newYearIsChange, newYearIsDateValid, newYearIsInvitationNameValid } from '../../../store/slices/NewYearSlice/NewYearSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectData } from '../../../store/slices/GetProjectSlice/GetProjectSlice';
import { newYearCalendarIcon, newYearFileIcon } from '../../../iconsFolder/icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { changeBirthDay_section_2_images } from '../../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice';
import { useTranslation } from 'react-i18next';

function NewYearHeader({index, section }) {
  const [viewYaarDrop, setViewYaarDrop] = useState(false);
  const [file, setFile] = useState("");
  const [textValue, setTextValue] = useState('');
  const respProjectData = useSelector(selectProjectData);
  const newYearInfo = useSelector(newYearAllInfo);
  const isChange = useSelector(newYearIsChange);
  const dateValid = useSelector(newYearIsDateValid);
  const yearValid = useSelector(newYearIsInvitationNameValid);
  const dispatch = useDispatch();
  const {t} = useTranslation()

  const dropdownRef = useRef(null);
  const datePickerRef = useRef(null); // Reference to the DatePicker component

  const handleDateChange = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const day = String(date.getDate()).padStart(2, '0');
  
    const formattedDate = `${year}-${month}-${day}`;
    dispatch(changeNewYearDate(formattedDate));
  };

  const changeYear = (year) => {
    dispatch(changeNewYearInvitationName(year));
  };

  // Handle calendar icon click to open the date picker
  const handleCalendarIconClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setFocus();
    }
  };


  useEffect(() => {
    // Close the dropdown when clicking outside
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setViewYaarDrop(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileBlob = new Blob([selectedFile], { type: selectedFile.type });
      console.log(fileBlob,'-----------');
      
      dispatch(changeNewYearSection1Images(fileBlob));
  }
    setFile(selectedFile);
    setTextValue(selectedFile ? selectedFile.name.slice(0, 30) : '');
};

const handleTextChange = (e) => {
 
    setTextValue(e.target.value);
};


const imageURL = section?.images[0].path ||( file ? URL.createObjectURL(file) : webexLogo);

// console.log(,'aayyaya');

  return (
    <div className="new_year_header">
      <div className="container">
        <div className="new_year_header_content">
          <div className="new_year_header_content_title">
            <h3 className="new_year_header_content_title_1">Happy</h3>
            <h1 className="new_year_header_content_title_2">New Year</h1>
            {isChange === 'view' ? (
              <h2 className="new_year_header_content_title_3">
                {respProjectData?.data?.invitation_name || newYearInfo?.invitation_name || '2025'}
              </h2>
            ) : (
              <div className="new_year_header_content_select">
                <div className="new_year_header_content_select_date">
                  <h2 className="new_year_header_content_select_title">
                    {t('new_year_placeholders.4')}
                  </h2>
                  <div  className={`new_year_header_content_select_date_input_div ${dateValid ? 'valid_error' : ''}`}>
                    <DatePicker
                      ref={datePickerRef} 
                      selected={newYearInfo?.date}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      className='date-picker'
                    />
                    <span onClick={handleCalendarIconClick}>{newYearCalendarIcon}</span>
                  </div>
                </div>
                <div className="new_year_header_content_select_year_div">
                  <h2 className="new_year_header_content_select_title">
                    {t('new_year_placeholders.5')}
                  </h2>
                  <div
                    className="new_year_header_content_select_year_input_div"
                    onClick={() => setViewYaarDrop(!viewYaarDrop)}
                    ref={dropdownRef}
                  >
                    <input
                      style={{outline: yearValid ? '2px solid red' : 'none'}}
                      readOnly
                      type="text"
                      value={newYearInfo?.invitation_name || '2025'}
                    />
                    <span>{newYearCalendarIcon}</span>
                  </div>
                  {viewYaarDrop && (
                    <ul className="new_year_header_content_select_year_select_options">
                      <li onClick={(e) => changeYear(e.target.innerText)}>2025</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2026</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2027</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2028</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2029</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2030</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2031</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2032</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2033</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2034</li>
                      <li onClick={(e) => changeYear(e.target.innerText)}>2035</li>
                    </ul>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="new_year_header_content_img_and_text">
            { isChange === 'view' ? 
             ( <img src={imageURL} alt="logo" /> ) :
             ( <div className='input-text-div upload-file-div'>
              <h2 className="new_year_header_content_select_title">{t('new_year_placeholders.6')}</h2>
              <label className='fileIcon' htmlFor="fileInput">{newYearFileIcon}</label>
              <input style={{ display: 'none' }} type="file" id="fileInput" className='new_year_header_content_img_and_file_input' onChange={handleFileChange} />
              <input type="text" id="textInput" className='new_year_header_content_img_and_text_input' value={textValue} onChange={handleTextChange} />
          </div>)
            }
            { isChange === 'view' ?
              (<p>{section?.text || newYearInfo?.section_1_text || t('new_year_header_text')}</p>) :
              (
              
              <div>
                <h2 className="new_year_header_content_select_title">{t('new_year_placeholders.7')}</h2>
                <textarea className='new_year_header_content_textarea' type="text" value={newYearInfo?.section_1_text || ''}  onChange={(e) => dispatch(changeNewYearSection_1_text(e.target.value))}/>
              </div>
              )
            }
          </div>
          <img
            src={bottom_animation_img}
            alt="animation"
            className="new_year_header_conten_botom_animation"
          />
        </div>
      </div>
    </div>
  );
}

export default NewYearHeader;
