import React from 'react'
import NewYearHeader from './NewYearHeader/NewYearHeader'
import './NewYear.css'
import SnowAnimation from './SnowAnimation/SnowAnimation'
import NewHeaderTimerSection from './NewYearTimerSection/NewYearTimerSection'
import NewYearTimingSection from './NewYearTimingSection/NewYearTimingSection'
import NewYearLocationSection from './NewYearLocationSection/NewYearLocationSection'
import NewYearFixedButton from './NewYearFixedButton/NewYearFixedButton'
import NewYearContactSection from './NewYearContactSection/NewYearContactSection'
import { newYearIsChange } from '../../store/slices/NewYearSlice/NewYearSlice'
import { useSelector } from 'react-redux'
import NewYearFeedBackSection from './NewYearFeedBackSection/NewYearFeedBackSection'
import { selectProjectData } from '../../store/slices/GetProjectSlice/GetProjectSlice'
function NewYear() {
  const isChange = useSelector(newYearIsChange)
  const respProjectData = useSelector(selectProjectData);
  // console.log(JSON.parse(respProjectData.data.sections[1].json_field), 'respProjectData999');


  return (
    <div className='new_year'>

      {
        respProjectData?.data?.sections ? (respProjectData?.data?.sections.map((section, index) => {
          if (section.section_number === '1') {
            return (
              <>
                <NewYearHeader key={index} section={section} />
                <NewHeaderTimerSection key={index} section={section} />
              </>
            )
          }
          else if (section.section_number === '2') {
            return <NewYearTimingSection key={index} section={section} />
          }
          else if (section.section_number === '3') {
            return <NewYearLocationSection key={index} section={section} />
          }
        })) : (
          <>
            <NewYearHeader />
            <NewHeaderTimerSection />
            <NewYearTimingSection />
            <NewYearLocationSection />
          </>
        )
      }
      {isChange === 'view' ? <NewYearContactSection /> : <NewYearFeedBackSection />}
      <SnowAnimation />
      <NewYearFixedButton />
    </div>
  )
}

export default NewYear