import React from 'react'
import './NewYearTimerSection.css'
import Timer from '../../timer/Timer'
import { new_year_timer_section_tille_icon, new_year_timer_section_titleIcon2 } from '../../../iconsFolder/icons'
import { useSelector } from 'react-redux';
import { newYearAllInfo } from '../../../store/slices/NewYearSlice/NewYearSlice';
import { useTranslation } from 'react-i18next';
function NewHeaderTimerSection({index, section}) {
  const newYearInfo = useSelector(newYearAllInfo);
  const { t } = useTranslation();
  
  return (
    <div className='new_year_timer_section'>
       <div className='new_year_timer_section_timer1'>
            <div className='new_year_timer_section_timer1_title'>
                <span>{new_year_timer_section_tille_icon}</span>
                <h3>{t('new_year_timer_sectionTitles.0')}</h3>
            </div>
            <Timer newYearDate={`${newYearInfo?.invitation_name || `${new Date().getFullYear()}`}-12-31`}/>
       </div>

       <div className='new_year_timer_section_timer1'>
            <div className='new_year_timer_section_timer1_title'>
                <span>{new_year_timer_section_titleIcon2}</span>
                <h3>{t('new_year_timer_sectionTitles.1')}</h3>
            </div>
            <Timer allInfo={newYearInfo}/>
       </div>
    </div>
  )
}

export default NewHeaderTimerSection