import React, { useState } from 'react'
import './BirthDayFixedButons.css'
import { useDispatch, useSelector } from 'react-redux'
import { birthDayChangeEdit, birthDayChangeInfoSelector, birthDayChangeLAngModal, birthDayChangeView, birthDayInfoSelector, changeIsAgeValid, changeIsDateValid, changeIsFeedBackValid, changeIsInvitationNameValid } from '../../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice'
import { openModalPrivate } from '../../../store/slices/ModalPrivate/ModalPrivateSlice'
import { selectProjectData } from '../../../store/slices/GetProjectSlice/GetProjectSlice'
import { useTranslation } from 'react-i18next'

function BirthDayFixedButons() {
  const [count, setCount] = useState(1);
  const isChange = useSelector(birthDayChangeInfoSelector)
  const birthDayInfo = useSelector(birthDayInfoSelector)
  const respProjectData = useSelector(selectProjectData);
  const {t} = useTranslation()  

  const dispatch = useDispatch()

  const editTemplateBtn = () => {
    if (count === 1) {
      dispatch(birthDayChangeLAngModal())
    }
    dispatch(birthDayChangeEdit())
  }

  const viewTemplateBtn = () => {
    setCount(count + 1);
    dispatch(birthDayChangeView());
  };

  // const isSendDisabled = birthDayInfo.date === '' || birthDayInfo.feedback === '';

  const handleSendInfo = (e) => {
    
    if(birthDayInfo.date !== '' && birthDayInfo.feedback !== '' && birthDayInfo.invitation_name !== '' && birthDayInfo.age !== ''){
      dispatch(openModalPrivate('birth_day'));
    }

      if (!birthDayInfo.date) {
        dispatch(changeIsDateValid(true))
      }
      else{
        dispatch(changeIsDateValid(false))

      }


       if(!birthDayInfo.feedback){
        dispatch(changeIsFeedBackValid(true))
      }
      else{
        dispatch(changeIsFeedBackValid(false))

      }


       if(!birthDayInfo.invitation_name){
        dispatch(changeIsInvitationNameValid(true))
      }
      else{
        dispatch(changeIsInvitationNameValid(false))

      }


       if(!birthDayInfo.age){
         dispatch(changeIsAgeValid(true))
      }
      else{
        dispatch(changeIsAgeValid(false))
      }
  }


  function checkNonEmptyValues(obj) {
    return Object.values(obj).some(value => {
        return (Array.isArray(value) && value.length > 0) || (typeof value === 'string' && value.trim() !== '');
    });
}



  return (
    <>
      {(!respProjectData?.success) && isChange === 'view' && <button className='fixed_button_birth_day' onClick={editTemplateBtn}>{checkNonEmptyValues(birthDayInfo) ? t('projectButtons.1') : t('projectButtons.0')}</button>}
      {isChange === 'edit' && <button className='fixed_button_birth_day' onClick={viewTemplateBtn}>{t('projectButtons.2')}</button>}
     {(!respProjectData?.success) &&  <button  className='fixed_button_birth_day_send' onClick={handleSendInfo}>{t('projectButtons.4')}</button>}
    </>
  )
}

export default BirthDayFixedButons
