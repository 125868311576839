import React, { useEffect, useState } from 'react'
import './NewYearTimingSection.css'
import { new_year_timing_icon_1 } from '../../../iconsFolder/icons'
import { changeSection_2_timingArr, newYearAllInfo, newYearIsChange } from '../../../store/slices/NewYearSlice/NewYearSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
function NewYearTimingSection({index, section}) {
    const [timingDataState, setTimingDataState] = useState([]);
    const newYearInfo = useSelector(newYearAllInfo);
    const isChange = useSelector(newYearIsChange);
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const timingData = [
        {
            id: '1',
            name: t('new_year_timing_text.0'),
            time: '19:00',
        },

        {
            id: '2',
            name: t('new_year_timing_text.1'),
            time: '20:00',
        },

        {
            id: '3',
            name: t('new_year_timing_text.2'),
            time: '21:00',
        },

        {
            id: '4',
            name: t('new_year_timing_text.3'),
            time: '22:00',
        },
        {
            id: '5',
            name: t('new_year_timing_text.4'),
            time: '24:00',
        }
    ]

    
    const responseTimingData = section?.json_field 
    ? JSON.parse(section.json_field) 
    : null; 

    const timingDataRender = responseTimingData || timingData;

    


    const handleInputChange = (id, field, value) => {
      setTimingDataState((prev) => {
          const existingItem = prev.find((item) => item.id === id);
  
          if (existingItem) {
              return prev.map((item) =>
                  item.id === id ? { ...item, [field]: value } : item
              );
          } else {
              return [
                  ...prev,
                  {
                      id: id,
                      name: field === "name" ? value : existingItem?.name || "",
                      time: field === "time" ? value : existingItem?.time || "",
                  },
              ];
          }
      });
  };

  useEffect(() => {
      dispatch(changeSection_2_timingArr(timingDataState));
  }, [timingDataState, dispatch]);
    return (
        <div className="new_year_timing_section">
          <div className="container">
            <h2 className="new_year_timing_section_title">{t('new_year_timing_section_title')}</h2>
    
            {isChange === "view" ? (
              <div className="new_year_timing_section_content">
                {timingDataRender.map((item, index) => (
                  <div key={item.id} className="new_year_timing_section_content_item">
                    <span>{new_year_timing_icon_1}</span>
                    <div>
                      <h4>{newYearInfo?.section_2_timingArr[index]?.name || item.name}</h4>
                      <span>{newYearInfo?.section_2_timingArr[index]?.time || item.time}</span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="new_year_timing_section_edit_content">
                {timingData.map((item, index) => (
                  <div key={item.id} className="new_year_timing_section_edit_content_input_div">
                    <div className="new_year_timing_section_edit_content_input_text_div">
                      <span>{t('new_year_placeholders.8')}</span>
                      <input
                        type="text"
                        className="new_year_timing_section_edit_content_input_text"
                        defaultValue={timingDataState.find((data) => data.id === item.id)?.name || item.name}
                        onChange={(e) => handleInputChange(item.id, "name", e.target.value)}
                      />
                    </div>
    
                    <div className="new_year_timing_section_edit_content_input_time_div">
                      <span>{t('new_year_placeholders.9')}</span>
                      <input
                        type="time"
                        className="new_year_timing_section_edit_content_input_time"
                        defaultValue={newYearInfo?.section_2_timingArr[index]?.time || ""}
                        onChange={(e) => handleInputChange(item.id, "time", e.target.value)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      );
}

export default NewYearTimingSection