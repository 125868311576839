import React, { useEffect, useState } from 'react';
import './BirthDayOneImage.css';
import { invitationImages } from '../../../images/BirthDayImg/Images';
import { birthDayChangeInfoSelector, changeBirthDaySection_1_images } from '../../../store/slices/BirthDayInfoSlice/BirthDayInfoSlice';
import { useDispatch, useSelector } from 'react-redux';
import { galeriaIcon } from '../../../iconsFolder/icons';

function BirthDayOneImage({ idx }) {
    const dispatch = useDispatch();
    const isChange = useSelector(birthDayChangeInfoSelector);
    const [file, setFile] = useState(null);

    const handleChange = (e) => {
        const selectedFile = e.target.files[0];
        setFile(selectedFile);
    };

    useEffect(() => {
        if (file) {
            const fileBlob = new Blob([file], { type: file.type });
            console.log(fileBlob,'=====');
            
            dispatch(changeBirthDaySection_1_images({ index: idx, file: fileBlob }));
        }
    }, [file, dispatch, idx]);

    const imageURL = file ? URL.createObjectURL(file) : invitationImages[idx];

    return (
        <div className={`invitation_image_div invitation_image_${idx + 1}`}>
            <img src={imageURL} alt="not found" />
            {isChange === 'edit' && (
                <div>
                    <label>
                        <div className='galeria_icon'>{galeriaIcon}</div>
                        <input style={{ display: 'none' }} type="file" onChange={handleChange} />
                    </label>
                </div>
            )}
        </div>
    );
}

export default BirthDayOneImage;
