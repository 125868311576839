import { createSlice } from "@reduxjs/toolkit";

const birthDayChangeInfoSlice = createSlice({
    name: 'birthDayChangeInfo',
    initialState: {
        isChange: 'view',
        linkModal: false,
        lengModal: false,
        isDateValid: false,
        isAgeValid: false,
        isFeedBackValid: false,
        isInvitationNameValid: false,
        allInfo: {
            date: '',
            feedback: '',
            age: '',
            invitation_name: '',

            section_1_time: '',
            section_1_text: '',
            section_1_location_name: '',
            section_1_addres: '',
            section_1_images: [],

            section_2_images: [],

            section_2_AddressLink: '',
        }
    },
    reducers: {
        birthDayChangeEdit(state) {
            state.isChange = 'edit';
        },
        birthDayChangeView(state) {
            state.isChange = 'view';
        },

        birthDayChangeLinkModal(state) {
            state.linkModal = !state.linkModal;
        },

        birthDayChangeLAngModal(state) {
            state.lengModal = !state.lengModal;
        },


        // =================================

        changeBirthDayDate(state, action) {
            state.allInfo.date = action.payload;
        },
        changeBirthDayFeedback(state, action) {
            state.allInfo.feedback = action.payload;
        },
        changeBirthDayAge(state, action) {
            state.allInfo.age = action.payload;
        },

        changeBirthDaySection1_time(state, action) {
            state.allInfo.section_1_time = action.payload;
        },

        changeBirthDaySection_1_location_name(state, action) {
            state.allInfo.section_1_location_name = action.payload;
        },

        changeBirthDaySection_1_addres(state, action) {
            state.allInfo.section_1_addres = action.payload;
        },

        changeBirthDayInvitationName(state, action) {
            state.allInfo.invitation_name = action.payload;
        },

        changeBirthDaySection_1_text(state, action) {
            state.allInfo.section_1_text = action.payload;
        },

        changeBirthDaySection_1_images(state, action) {
            const { index, file } = action.payload;
            state.allInfo.section_1_images[index] = file;
            console.log(file, '+++++');
        },
        changeBirthDay_section_2_images(state, action) {
            const { index, file } = action.payload
            state.allInfo.section_2_images[index] = file
            
        },

        changeBirthDaySection_2_AddressLink(state, action) {
            state.allInfo.section_2_AddressLink = action.payload;
        },



        // ===================================


        changeIsDateValid(state, action) {
            state.isDateValid = action.payload
        },

        changeIsAgeValid(state, action) {
            state.isAgeValid = action.payload
            
        },

        changeIsFeedBackValid(state, action) {
            state.isFeedBackValid = action.payload
        },

        changeIsInvitationNameValid(state, action) {
            state.isInvitationNameValid = action.payload

        },
    },
});

export const birthDayChangeInfoSelector = (state) => state.birthDayChangeInfo.isChange;
export const birthDayInfoSelector = (state) => state.birthDayChangeInfo.allInfo;

export const birthDayChangeLinkModalSelector = (state) => state.birthDayChangeInfo.linkModal;
export const birthDayChangeLAngModalSelector = (state) => state.birthDayChangeInfo.lengModal;
export const birthDayChangeSelector = (state) => state.birthDayChangeInfo;

export const {
    birthDayChangeEdit,
    birthDayChangeView,
    changeBirthDayDate,
    changeBirthDayFeedback,
    changeBirthDayAge,
    changeBirthDaySection1_time,
    changeBirthDaySection_1_location_name,
    changeBirthDaySection_1_addres,
    changeBirthDayInvitationName,
    changeBirthDaySection_1_text,
    changeBirthDaySection_1_images,
    changeBirthDay_section_2_images,
    birthDayChangeLinkModal,
    changeBirthDaySection_2_AddressLink,
    birthDayChangeLAngModal,
    changeIsDateValid,
    changeIsAgeValid,
    changeIsFeedBackValid,
    changeIsInvitationNameValid,

} = birthDayChangeInfoSlice.actions;
export default birthDayChangeInfoSlice;
